import { useEffect, useState } from "react"
import Alert from "@mui/material/Alert"
import { languageSelector as ls } from "@covvi/language-selector"

import {
  useCurrentHandContext,
  useCurrentSessionContext,
  useRoomFunctionsContext,
  useServerStateContext,
} from "@context/remoteAssistProviders"
import { useAuth } from "@context/AuthContext"

import { NewTableOptions } from "@typesFolder/types"
import { Room } from "@typesFolder/remoteAssistTypes"
import RemoteAssistRoom from "@components/cards/RemoteAssistRoom"
import BasicModal from "@ui/modals/BasicModal"
import Table from "@ui/table/Table"
import { TableButtons } from "@ui/buttons/TableButtons"
import { NonAccreditationCard } from "@components/cards/NonAccreditationCard"
import WhatIsRemoteAssist from "@components/cards/WhatIsRemoteAssist"

const RemoteAssist = () => {
  const { roomId, client } = useCurrentSessionContext()
  const { liveRooms, archivedRooms, contextError } = useServerStateContext()
  const { joinRoom, closeRoom, getArchivedRoom, getArchived } = useRoomFunctionsContext()

  const { profile } = useAuth()
  const authenticated: boolean = !!(
    profile &&
    (["Admin", "Customer Service Team Member", "Tech Team Member"].includes(profile?.role) ||
      (profile.role === "Clinician" && profile.covvi_cert_accreditor))
  )

  const [liveSessions, setLiveSessions] = useState<Room[]>([])
  const [archivedSessions, setArchivedSessions] = useState<Room[]>([])

  const [error, setError] = useState<string | undefined>()
  const [tabSelectValue, setTabSelectValue] = useState<"Archived Sessions" | "Live Sessions">(
    "Live Sessions"
  )

  useEffect(() => setError(contextError), [contextError])

  useEffect(() => {
    // let timeout
    if (client) {
      // emitData({t})
      // timeout && clearInterval(timeout)
      // emitData({ type: "bleCommand", data: "HS0101" })
      // timeout = setTimeout(() => {
      //   emitData({ type: "bleCommand", data: "HS0100" })
      // }, 6000)
    }

    // return () => emitData({ type: "bleCommand", data: "HS0100" })
  }, [client])

  useEffect(() => {
    archivedRooms &&
      setArchivedSessions(
        archivedRooms!.map((room) => {
          return {
            ...room,
            date: room.createdOn?._seconds * 1000,
            serialNumber: room.serialNumber || ls.getText("None"),
            roomOptions: (
              <TableButtons main={{ title: "View", onClick: () => getArchivedRoom(room.id) }} />
            ),
          }
        })
      )
    liveRooms &&
      setLiveSessions(
        liveRooms!.map((room) => {
          return {
            ...room,
            user: room.userName || ls.getText("None"),
            technician: room.technicians?.length ? room.technicians[0] : ls.getText("None"),
            roomOptions: (
              <TableButtons
                main={{ title: "Join", onClick: () => joinRoom(room.id) }}
                more={{ title: "Close Session", onClick: () => closeRoom(room.id) }}
              />
            ),
          }
        })
      )
  }, [archivedRooms, liveRooms])

  const generalOptions = {
    pillArray: {
      buttons: [
        {
          title: "Live Sessions",
          onClick: () => setTabSelectValue("Live Sessions"),
        },
        {
          title: "Archived Sessions",
          onClick: () => {
            getArchived()
            setTabSelectValue("Archived Sessions")
          },
        },
      ],
      selected: tabSelectValue,
      setSelected: () => setTabSelectValue,
    },
    dontSort: true,
  }
  const liveOptions: NewTableOptions = {
    ...generalOptions,
    fieldIds: ["id", "user", "technician", "roomOptions"],
    fieldTitles: ["Room ID", "User", "Technician"],
    searchParams: ["id", "user", "technician"],
  }
  const archivedOptions: NewTableOptions = {
    ...generalOptions,
    fieldIds: ["id", "date", "serialNumber", "roomOptions"],
    fieldTitles: ["Room ID", "Date Created", "Associated Hand"],
    searchParams: ["id", "date", "serialNumber"],
  }

  return authenticated ? (
    <>
      {roomId ? (
        <RemoteAssistRoom showingArchived={tabSelectValue === "Archived Sessions"} />
      ) : (
        <>
          <WhatIsRemoteAssist />
          <Table
            data={tabSelectValue === "Archived Sessions" ? archivedSessions : liveSessions}
            tableOptions={tabSelectValue === "Archived Sessions" ? archivedOptions : liveOptions}
          />
        </>
      )}

      {error && (
        <BasicModal open={error ? true : false} setClosed={() => setError(undefined)}>
          <Alert severity={"error"} onClose={() => setError(undefined)}>
            {error}
          </Alert>
        </BasicModal>
      )}
    </>
  ) : (
    <NonAccreditationCard />
  )
}

export default RemoteAssist
