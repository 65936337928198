import { Routes, Route } from "react-router-dom"

import { AuthProvider } from "./context/AuthContext"
import { RemoteAssistProvider } from "@context/RemoteAssistContext"

import AuthedRoute from "./routes/AuthedRoute"

import MyAccount from "@pages/account/MyAccount"
import Login from "@pages/login/Login"
import Users from "@pages/users/Users"
import User from "@pages/user/User"
import Hands from "@pages/hands/Hands"
import ViewHand from "@pages/hands/ViewHand"
import ViewHandConfig from "@pages/hands/ViewHandConfig"
import SupportTickets from "@pages/support/SupportTickets"
import ViewTicket from "@pages/support/ViewTicket"
import RemoteAssist from "@pages/remoteAssist/RemoteAssist"
import Layout from "@components/layout/Layout"
import ErrorPage from "@pages/error/ErrorPage"
import AccountManagement from "@pages/accountManagement/AccountManagement"
import Returns from "@pages/returns/Returns"
import Translation from "@pages/translations/Translations"

const MyAccountPage = () => {
  return (
    <AuthedRoute title={"Account Management"} requiresLogin>
      <MyAccount />
    </AuthedRoute>
  )
}

const UsersPage = () => {
  return (
    <AuthedRoute title={"Users"}>
      <Users />
    </AuthedRoute>
  )
}

const UserPage = () => {
  return (
    <AuthedRoute title={"Users"} subTitle={"View User"}>
      <User />
    </AuthedRoute>
  )
}

const RemoteAssistPage = () => {
  return (
    <AuthedRoute title={"Remote Assist"}>
      <RemoteAssistProvider>
        <RemoteAssist />
      </RemoteAssistProvider>
    </AuthedRoute>
  )
}

const HandsPage = () => {
  return (
    <AuthedRoute title={"Hands"}>
      <Hands />
    </AuthedRoute>
  )
}

const ViewHandPage = () => {
  return (
    <AuthedRoute title={"Hands"} subTitle={"View Hand"}>
      <ViewHand />
    </AuthedRoute>
  )
}

const ViewHandConfigPage = () => {
  return (
    <AuthedRoute title={"Hands"} subTitle={"View Hand Config"}>
      <ViewHandConfig />
    </AuthedRoute>
  )
}

const SupportTicketsPage = () => {
  return (
    <AuthedRoute title={"Support Tickets"}>
      <SupportTickets />
    </AuthedRoute>
  )
}

const ViewTicketPage = () => {
  return (
    <AuthedRoute title={"Support Tickets"} subTitle={"View Ticket"}>
      <ViewTicket />
    </AuthedRoute>
  )
}

const ReturnsPage = () => {
  return (
    <AuthedRoute title={"Returns"}>
      <Returns />
    </AuthedRoute>
  )
}
const TranslationsPage = () => {
  return (
    <AuthedRoute title={"Translations"}>
      <Translation />
    </AuthedRoute>
  )
}

const LoginPage = () => {
  return (
    <Layout>
      <Login />
    </Layout>
  )
}

const AccountManageMentPage = () => {
  return (
    <Layout>
      <AccountManagement />
    </Layout>
  )
}

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<MyAccountPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/acctmgmt" element={<AccountManageMentPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/users/:userId" element={<UserPage />} />
        <Route path="hands" element={<HandsPage />} />
        <Route path="hands/:handId" element={<ViewHandPage />} />
        <Route path="hands/:handId/:config" element={<ViewHandConfigPage />} />
        <Route path="/support-tickets" element={<SupportTicketsPage />} />
        <Route path="/support-tickets/:ticketId" element={<ViewTicketPage />} />
        <Route path="/remote-assist" element={<RemoteAssistPage />} />
        <Route path="/remote-assist/:roomId" element={<RemoteAssistPage />} />
        <Route path="/returns" element={<ReturnsPage />} />
        <Route path="/translation" element={<TranslationsPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </AuthProvider>
  )
}

export default App
